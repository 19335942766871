import allRoutes from '@/router/routes/all.js'
import privateAll from '@/router/routes/privateAll.js'
import privateRoutes from '@/router/routes/private.js'
import publicRoutes from '@/router/routes/public.js'
import masterRoutes from '@/router/routes/master.js'
import inventoryRoutes from '@/router/routes/inventory.js'
import configsRoutes from '@/router/routes/configs.js'

export default allRoutes.concat(
  publicRoutes,
  privateAll,
  privateRoutes,
  masterRoutes,
  inventoryRoutes,
  configsRoutes
)
