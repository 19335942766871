<template>
  <div>
    <v-app-bar
      fixed
      app
      height="60"
      color="#d31145"
      elevation="0">
      <v-app-bar-nav-icon 
        dark
        @click.stop="drawer = !drawer">
      </v-app-bar-nav-icon>

      <v-toolbar-title
        class="text-capitalize font-weight-bold white--text pl-2">
        {{ $route.meta.title }}
      </v-toolbar-title>
    </v-app-bar>

    <div 
      v-if="$route.name === 'home'" 
      @click="storeDisabled" 
      class="d-flex justify-space-between cursor-pointer pa-0" 
      v-ripple 
      style="background-color:#fff;width:100%;margin-top:55px;position:fixed">
      <div class="d-flex py-3">
        <img width="40" v-if="user.profile && user.profile.store_selected_object.image_url" height="40" style="margin-left:15px" :src="user.profile && user.profile.store_selected_object.image_url" alt="">
        <v-avatar v-else tile class="rounded-lg ml-3" color="#d31145">
          <span class="white--text">{{ aliasName }}</span>
        </v-avatar>
        <v-list-item-content class="py-0 mt-0 ml-3">
          <v-list-item-title class=" pt-0" style="font-weight:bold;font-size:15px">
            {{ user.profile ? user.profile.store_selected_object.name : '' }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ user.profile ? user.profile.store_selected_object.role : '' }}
          </v-list-item-subtitle>
          <v-spacer></v-spacer>
        </v-list-item-content>
      </div>
      <div class="d-flex justify-center align-center mr-5">
        <img :src="require('@/assets/images/icon_store.png')" width="30" height="30" alt="">
      </div>
    </div>

    <v-navigation-drawer
      dark
      color="#d31145"
      v-model="drawer"
      fixed
      temporary
      absolute>
      <div v-if="Object.keys(user).length > 0"
        class="white--text text-left pa-3 mb-3">
        <div class="mb-3">
          <router-link to="/config/profile">
            <v-avatar tile class="rounded-xl" size="80">
              <v-img 
                :src="user.profile.image_url.length > 0 
                  ? user.profile.image_url 
                  : require('@/assets/images/_default.png')">
                <template v-slot:placeholder>
                  <v-row 
                    class="fill-height ma-0" 
                    align="center" 
                    justify="center">
                    <v-progress-circular 
                      indeterminate 
                      color="#d31145">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-avatar>
          </router-link>

          <v-btn 
            style="z-index: 9"
            icon 
            class="float-right" 
            @click="drawer = false">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </div>
        
        <router-link
          to="/profile"
          class="white--text">
          <div class="title text-capitalize">
            {{ user.profile.name.length > 0 ? user.profile.name : ''}}
          </div>

          <div class="caption mb-1">
            {{ user.profile.email.length > 0 ? user.profile.email : ''}}
          </div>
          
          <div class="body-2">
            ({{ user.have_store_bool ? user.profile.store_selected_object.name : 'Belum Punya Toko'}})
          </div>
        </router-link>
      </div>

      <v-list class="py-2" nav dense v-if="user.have_store_bool">
        <v-list-item 
          link 
          @click="storeDisabled">
          <v-list-item-icon class="mr-5">
            <v-icon size="20" color="white" style="opacity: 50%">mdi-storefront</v-icon>
          </v-list-item-icon>

          <v-list-item-title class="white--text" style="opacity: 50%">
            Ubah Toko
          </v-list-item-title>
        </v-list-item>
      </v-list>
      
      <v-list
        dense
        nav
        v-for="(item, index) in menu"
        :key="index"
        class="py-2">
        <v-list-item
          link
          @click="drawer = false"
          :style="item.href.split('/')[1] === $route.path.split('/')[1] ? 'background-color: #fff' : ''"
          :to="item.href">
          <v-list-item-icon class="mr-5">
            <v-tooltip right>
              <template v-slot:activator="{on, attrs}">
                <v-icon size="20" v-on="on"
                :style="item.href.split('/')[1] === $route.path.split('/')[1]
                    ? 'color: #d31145;'
                    : 'color: #fff; opacity: 50%'" 
                    v-bind="attrs">
                    {{ item.icon }}
                  </v-icon>
              </template>
              <span>
                {{ item.text }}
              </span>
            </v-tooltip>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title 
              :style="item.href.split('/')[1] === $route.path.split('/')[1] 
                ? 'color:#d31145; font-weight: bold;' 
                : 'color:#fff;opacity: 50%'">
              {{ item.text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-list class="py-2" nav dense>
        <v-list-item link @click="logout">
          <v-list-item-icon class="mr-5">
            <v-icon size="20" style="color:#fff;opacity: 50%">mdi-lock</v-icon>
          </v-list-item-icon>

          <v-list-item-title style="color:#fff;opacity: 50%">
            Logout
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-dialog v-model="dialog.selectStore" scrollable persistent width="380">
      <v-card rounded="lg">
        
        <v-card-title 
          class="subtitle-1 text-capitalize font-weight-bold white--text justify-center py-2" 
          style="
            background-color: #d31145;
            height:50px;">
          <div 
            style="
              flex: 1; 
              display: flex; 
              justify-content: center">
              Plih Toko ({{ user.profile ? user.profile.store_array.length : 0 }})
          </div>

          <v-icon @click="dialog.selectStore = false" color="#fff">mdi-close-circle-outline</v-icon>
          
        </v-card-title>

        <v-divider></v-divider>
        
        <v-card-text class="px-0" style="height:320px">

          <v-card
            flat
            class="mx-auto">

            <v-list class="py-0">

              <v-list-item-group>

                <v-list-item
                  v-for="(item, i) in user.profile ? user.profile.store_array : []"
                  :key="i"
                  @click="updateStore(item)">

                  <v-list-item-avatar>
                    <img :src="item.image_url == '' ? require('@/assets/images/icon_store.png') : item.image_url" alt="" width="30">
                  </v-list-item-avatar>

                  <v-list-item-content>

                    <v-list-item-title style="font-size: 14px;" class="font-weight-bold text-title" v-text="item.name"></v-list-item-title>

                    <v-list-item-subtitle v-text="item.role"></v-list-item-subtitle>

                  </v-list-item-content>

                  <v-list-item-icon>
                    
                    <v-icon 
                      :color="item.id == user.profile.store_selected_object.id ? '#d31145' : ''"  
                      :style="item.id == user.profile.store_selected_object.id ? 
                      'background-color:rgba(245, 0, 87, 0.08);padding:9px;' : 'padding:9px;'" class="rounded-circle">
                      {{ item.id == user.profile.store_selected_object.id ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank' }}
                    </v-icon>
                  
                  </v-list-item-icon>
                
                </v-list-item>
              
              </v-list-item-group>
            
            </v-list>
          
          </v-card>
        
        </v-card-text>
      
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import firebaseapp from "firebase";
  import firebase from "@/plugins/Firebase";
  export default {
    data() {
      return {
        drawer: false,
        menu: [
          {
            text: 'Home',
            icon: 'mdi-home',
            href: '/dashboard',
            parent: 0
          },
          {
            text: 'Data Master',
            icon: 'mdi-book-open-variant',
            href: '/master',
            parent: 0
          },
          {
            text: 'Persediaan',
            icon: 'mdi-clipboard-file',
            href: '/inventory',
            parent: 0
          },
          {
            text: 'Konfigurasi',
            icon: 'mdi-cog',
            href: '/config',
            parent: 0
          },
          {
            text: 'Kasir Pos',
            icon: 'mdi-monitor-dashboard',
            href: '/',
            parent: 0
          },
          {
            text: 'Transaksi',
            icon: 'mdi-refresh-circle',
            href: '/',
            parent: 0
          }
        ],
        process: {
          run: false,
        },
        dialog: {
          selectStore: false,
        }
      }
    },
    watch: {
      $route(to, from) {
        
      }
    },
    components: {
      
    },
    computed: {
      user(){
        return this.$store.state.user
      },
      aliasName() {
        let firstAlias = "";
        let secondAlias = "";
        if (this.user && this.user.profile.name) {
          let name = this.user.profile.name;
          let splitname = name.split(" ");

          if (splitname[0]) {
            firstAlias = splitname[0].substring(0, 1);
          }

          if (splitname[1]) {
            secondAlias = splitname[1].substring(0, 1);
          }
        }
        return firstAlias + secondAlias;
      },
    },
    created() {
      
    },
    mounted() {
      
    },
    methods: {
      updateStore(item){
        if (item.id == this.user.profile.store_selected_object.id) {
          // console.log('store selected', item);
          this.dialog.selectStore = false
          this.$store.state.snackbar.state = true
          this.$store.state.snackbar.timeout = 2000
          this.$store.state.snackbar.content = `Anda sudah berada pada toko ${item.name}. Pilih toko lainnya.`
          return
        }else {
          this.dialog.selectStore = false
          this.$store.state.snackbar.state = true
          this.$store.state.snackbar.timeout = 2000
          this.$store.state.snackbar.content = `Anda sekarang berada pada toko ${item.name}.`
          let getStorage = JSON.parse(localStorage.getItem('user'))
  
          getStorage.profile.store_selected_object = item
  
          localStorage.setItem('user', JSON.stringify(getStorage))
          setTimeout(() => {
            window.location.reload()
          }, 2000)

        }


      },
      storeDisabled() {
        this.dialog.selectStore = true;
        this.drawer = false;
      },

      logout() {
        this.$store.state.overlay.state = true;
        this.drawer = false;
        this.$post(`auth/logout`).then((response) => {
          if (response.status === 200){
            firebase.auth().signOut().then((response) => {
              firebaseapp
              .messaging()
              .getToken()
              .then((oldToken) => {
                if (oldToken === localStorage.getItem("TOKEN_FCM")) {
                  console.log("Token delete ", oldToken);
                  firebaseapp.messaging().deleteToken({ token: oldToken });
                  localStorage.setItem('TOKEN_FCM', '')
                } else {
                  console.log("Token is deleted");
                }
              });
            }).catch((error) => {
              
            })
            
            this.$store.state.overlay.state = false
            this.$store.state.snackbar.state = true
            this.$store.state.snackbar.content = "Berhasil Logout"

            localStorage.clear()
            window.location.href = "/login"
          }
        })
      }
    }
  }
</script>