const DataMaster = () => import(/* webpackPrefetch: true */ '@/views/private/master/Index.vue');

// STORE
const DataStore = () => import(/* webpackPrefetch: true */ '@/views/private/master/store/Index.vue');
const StoreForm = () => import(/* webpackPrefetch: true */ '@/views/private/master/store/Form.vue');

// PEGAWAI
const DataUser = () => import(/* webpackPrefetch: true */ '@/views/private/master/user/Index.vue');
const UserForm = () => import(/* webpackPrefetch: true */ '@/components/user/Form.vue');

// SALES TYPE
const DataSalesType = () => import(/* webpackPrefetch: true */ '@/views/private/master/salesType/Index.vue');
const SalesTypeForm = () => import(/* webpackPrefetch: true */ '@/views/private/master/salesType/Form.vue');


// CATEGORY PRODUCT
const DataCategory = () => import(/* webpackPrefetch: true */ '@/views/private/master/category/Index.vue');
const CategoryForm = () => import(/* webpackPrefetch: true */ '@/views/private/master/category/Form.vue');

// PRODUCT
const DataProduct = () => import(/* webpackPrefetch: true */ '@/views/private/master/product/Index.vue');
const ProductForm = () => import(/* webpackPrefetch: true */ '@/views/private/master/product/Form.vue');

const routes = [
  {
    path: "/master",
    name: "data master",
    component: DataMaster,
    meta: {
      title: "Data Master"
    }
  },

  // STORE
  {
    path: "/master/store",
    name: "toko",
    component: DataStore,
    meta: {
      title: "Data Toko"
    }
  },
  {
    path: "/master/store/form",
    name: "buat toko",
    component: StoreForm,
    meta: {
      title: "Buat Toko Anda"
    }
  },

  // PEGAWAI
  {
    path: "/master/user",
    name: "user",
    component: DataUser,
    meta: {
      title: "Pegawai"
    }
  },
  {
    path: "/master/user/form",
    name: "user_form",
    component: UserForm,
    meta: {
      title: "Pegawai"
    }
  },

  // SALES_TYPE
  {
    path: "/master/sales-type",
    name: "tipe penjualan",
    component: DataSalesType,
    meta: {
      title: "Tipe Penjualan"
    }
  },
  {
    path: "/master/sales-type/form",
    name: "create_sales_type",
    component: SalesTypeForm,
    meta: {
      title: "Tambah Tipe Penjualan"
    }
  },

  // CATEGORY_PRODUCT
  {
    path: "/master/category",
    name: "kategori",
    component: DataCategory,
    meta: {
      title: "Kategori Produk"
    }
  },
  {
    path: "/master/category/form",
    name: "create_category",
    component: CategoryForm,
    meta: {
      title: "Tambah Kategori Produk"
    }
  },

  // PRODUCT
  {
    path: "/master/product",
    name: "produk",
    component: DataProduct,
    meta: {
      title: "Produk"
    }
  },
  {
    path: "/master/product/form",
    name: "create_product",
    component: ProductForm,
    meta: {
      title: "Tambah Produk"
    }
  },
]

export default routes.map(route => {
  const meta = {
    all: false, 
    public: false,
    title: route.meta.title
  }
	return { ...route, meta }
})