<template>
  <v-app>
    <Navbar
      v-if="
        $route.name !== 'login' &&
        $route.name !== '404' && 
        $route.name !== 'buat toko' && 
        $route.name !== 'profile' && 

        $route.name !== 'tipe penjualan' && 
        $route.name !== 'create_sales_type' && 

        $route.name !== 'produk' &&
        $route.name !== 'create_product' &&

        $route.name !== 'kategori' &&
        $route.name !== 'create_category' &&

        $route.name !== 'toko' &&
        $route.name !== 'stok' &&
        $route.name !== 'stok_card' && 
        $route.name !== 'stok_adjustment' &&
        $route.name !== 'stok_conversion' &&

        $route.name !== 'user' &&
        $route.name !== 'user_form'"
        
        />

    <v-dialog
      v-model="dialog.store.create"
      fullscreen
      hide-overlay
      persistent
      transition="dialog-bottom-transition">
      <v-card flat>
        <StoreCreate @close="dialog.store.create = false"/>
      </v-card>
    </v-dialog>


    <v-sheet 
      min-height="100vh" 
      light
      style="background: #f9f9f9;">
      <router-view  />
    </v-sheet>

    <v-snackbar 
      bottom
      absolute
      width="100%"
      style="z-index: 999"
      class="px-3 mb-16"
      :timeout="$store.state.snackbar.timeout"
      v-model="$store.state.snackbar.state">
      <span style="color: #fff" class="text-capitalize">
        {{ $store.state.snackbar.content }}
      </span>

      <template v-slot:action="{ attrs }">
        <v-btn
          color="orange"
          text
          v-bind="attrs"
          @click="$store.state.snackbar.state = false">
          BAIK
        </v-btn>
      </template>
    </v-snackbar>

    <v-overlay 
      :value="$store.state.overlay.state" 
      z-index="9" 
      opacity="0.6"
      class="text-center">
      <v-chip
        color="#d31145"
        class="ma-2">
        <v-progress-circular 
          indeterminate 
          size="22">
        </v-progress-circular>
        
        <div class="pa-2">
          {{$store.state.overlay.content}}
        </div>
      </v-chip>
    </v-overlay>
  </v-app>
</template>

<script>
  import { TokenService } from "@/services/Storage.Service";
  import Navbar from "./components/Navbar";
  import firebaseapp from "firebase";
  import firebase from "./plugins/Firebase";
  export default {
    data() {
      return {
        dialog: {
          store: {
            create: this.$store.state.authenticated && !this.$store.state.user.have_store_bool ? true : false
          }
        }
      }
    },
    components: {
      Navbar,
      StoreCreate: () => import('@/components/store/create')
    },
    computed: {
      
    },
    watch: {
      $route(to, from) {
        this.tokenFirebase();
        if (this.$route.path != "/login") {
          this.preventPrevilage();
        }
      }
    },
    created() {
      if (process.browser) {
        if ("Notification" in window && "serviceWorker" in navigator) {
          this.tokenFirebase();
        }
      }
    },
    mounted() {

    },
    methods: {
      tokenFirebase() {
        if (firebase.messaging.isSupported()) {
          navigator.permissions
            .query({ name: "notifications" })
            .then(function(result) {
              if (result.state !== "granted") {
                firebaseapp
                  .messaging()
                  .Notification.requestPermission()
                  .then(function() {
                    window.location.reload();
                  })
                  .catch(function(err) {
                    console.log("Unable to get permission to notify", err);
                  });
              }
            });

          firebaseapp
            .messaging()
            .getToken()
            .then((refreshedToken) => {
              console.log("Token refresh ", refreshedToken);
              this.updateTokenFirebase(refreshedToken);
            })
            .catch(function(err) {
              console.log("Unable to retrieve token", err);
          });
        }
      },
      async updateTokenFirebase(refreshedToken) {
        if (refreshedToken !== localStorage.getItem("TOKEN_FCM")) {
          localStorage.setItem("TOKEN_FCM", refreshedToken);
        }
      },
      preventPrevilage() {
        if (TokenService.getUser()) {
          if (!JSON.parse(TokenService.getUser()).user_privilage) {
            TokenService.removeToken();
            window.location = "/login";
          }
        }
      }
    }
  }
</script>
