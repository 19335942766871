import * as firebase from 'firebase/app';
// import firebase from "firebase/app";
import "firebase/messaging";

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";

// Add the Firebase products that you want to use
// import "firebase/auth";
import "firebase/firestore";

// const firebaseConfig = {
//   apiKey: "AIzaSyARDfCVbBMPIbrykv9HJpbZrq_IWjKAHmk",
//   authDomain: "soda-pos-official.firebaseapp.com",
//   projectId: "soda-pos-official",
//   storageBucket: "soda-pos-official.appspot.com",
//   messagingSenderId: "679274511317",
//   appId: "1:679274511317:web:e2d18819d02acbbd325b5c",
//   measurementId: "G-RQG6V6TPHZ",
//   messagingSenderId: '679274511317'
// };

const firebaseConfig = {
  apiKey: "AIzaSyARDfCVbBMPIbrykv9HJpbZrq_IWjKAHmk",
  authDomain: "soda-pos-official.firebaseapp.com",
  databaseURL: "https://soda-pos-official-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "soda-pos-official",
  storageBucket: "soda-pos-official.appspot.com",
  messagingSenderId: "679274511317",
  appId: "1:679274511317:web:e2d18819d02acbbd325b5c",
  measurementId: "G-RQG6V6TPHZ"
};
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig)
}

const messaging = firebase.messaging()

messaging.getToken({vapidKey: "BPBIfPmID37Olhp6RqeNKJHiVbBw_iu4l3hSFjyKSOvp-faPReIJxs09age9NHTr8cRlEdk7PicRBaAcGvVOHP0"})

export default firebase