import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

if (localStorage.getItem('user') !== null) {
  store.commit('setUser', JSON.parse(localStorage.getItem('user')))
}

import '@/assets/css/main.css'

import  ApiHelpers from '@/services/ApiHelper'

Vue.prototype.$get = ApiHelpers.get
Vue.prototype.$post = ApiHelpers.post

import Convert from '@/plugins/Convert'
import Lodash from '@/plugins/Lodash'
import ScrollLoader from '@/plugins/ScrollLoader'
import Firebase from '@/plugins/Firebase'
import VCurrencyField from '@/plugins/VCurrencyField'
import VeeValidate from '@/plugins/VeeValidate'
import VueTheMask from '@/plugins/VueTheMask'
import Vue2DatePicker from '@/plugins/Vue2DatePicker'
import VueApexCharts from '@/plugins/VueApexCharts'
import VueCtkDateTimePicker from '@/plugins/VueCtkDateTimePicker'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  ApiHelpers,
  vuetify,
  Convert,
  Lodash,
  ScrollLoader,
  Firebase,
  VCurrencyField,
  VeeValidate,
  VueTheMask,
  Vue2DatePicker,
  VueApexCharts,
  VueCtkDateTimePicker,
  render: h => h(App)
}).$mount('#app')
