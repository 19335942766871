const Berita = () => import(/* webpackPrefetch: true */ '@/views/private/Berita.vue');

const routes = [
  {
    path: "/berita",
    name: "berita",
    component: Berita,
    meta: {
      title: "Berita"
    }
  },
]

export default routes.map(route => {
  const meta = {
    all: false, 
    public: false,
    title: route.meta.title
  }
	return { ...route, meta }
})