const Dashboard = () => import(/* webpackPrefetch: true */ '@/views/private/Dashboard.vue');
const Customers = () => import(/* webpackPrefetch: true */ '@/views/private/customers/Index.vue');

const routes = [
  {
    path: "/dashboard",
    name: "home",
    component: Dashboard,
    meta: {
      title: "Home"
    }
  },
  {
    path: "/customers",
    name: "pelanggan",
    component: Customers,
    meta: {
      title: "Data Pelanggan"
    }
  },
]

export default routes.map(route => {
  const meta = {
    all: false, 
    public: false,
    title: route.meta.title
  }
  return { ...route, meta }
})