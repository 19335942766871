var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[(
      _vm.$route.name !== 'login' &&
      _vm.$route.name !== '404' && 
      _vm.$route.name !== 'buat toko' && 
      _vm.$route.name !== 'profile' && 

      _vm.$route.name !== 'tipe penjualan' && 
      _vm.$route.name !== 'create_sales_type' && 

      _vm.$route.name !== 'produk' &&
      _vm.$route.name !== 'create_product' &&

      _vm.$route.name !== 'kategori' &&
      _vm.$route.name !== 'create_category' &&

      _vm.$route.name !== 'toko' &&
      _vm.$route.name !== 'stok' &&
      _vm.$route.name !== 'stok_card' && 
      _vm.$route.name !== 'stok_adjustment' &&
      _vm.$route.name !== 'stok_conversion' &&

      _vm.$route.name !== 'user' &&
      _vm.$route.name !== 'user_form')?_c('Navbar'):_vm._e(),_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","persistent":"","transition":"dialog-bottom-transition"},model:{value:(_vm.dialog.store.create),callback:function ($$v) {_vm.$set(_vm.dialog.store, "create", $$v)},expression:"dialog.store.create"}},[_c('v-card',{attrs:{"flat":""}},[_c('StoreCreate',{on:{"close":function($event){_vm.dialog.store.create = false}}})],1)],1),_c('v-sheet',{staticStyle:{"background":"#f9f9f9"},attrs:{"min-height":"100vh","light":""}},[_c('router-view')],1),_c('v-snackbar',{staticClass:"px-3 mb-16",staticStyle:{"z-index":"999"},attrs:{"bottom":"","absolute":"","width":"100%","timeout":_vm.$store.state.snackbar.timeout},scopedSlots:_vm._u([{key:"action",fn:function(ref){
      var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"orange","text":""},on:{"click":function($event){_vm.$store.state.snackbar.state = false}}},'v-btn',attrs,false),[_vm._v(" BAIK ")])]}}]),model:{value:(_vm.$store.state.snackbar.state),callback:function ($$v) {_vm.$set(_vm.$store.state.snackbar, "state", $$v)},expression:"$store.state.snackbar.state"}},[_c('span',{staticClass:"text-capitalize",staticStyle:{"color":"#fff"}},[_vm._v(" "+_vm._s(_vm.$store.state.snackbar.content)+" ")])]),_c('v-overlay',{staticClass:"text-center",attrs:{"value":_vm.$store.state.overlay.state,"z-index":"9","opacity":"0.6"}},[_c('v-chip',{staticClass:"ma-2",attrs:{"color":"#d31145"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"22"}}),_c('div',{staticClass:"pa-2"},[_vm._v(" "+_vm._s(_vm.$store.state.overlay.content)+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }