import Vue from "vue"
import Vuex from "vuex"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    overlay: {
      state: false,
      content: "Loading..."
    },
    snackbar: {
      state: false, 
      timeout: 2000,
      content: "Berhasil"
    },
    authenticated: false,
    user: {}
  },
  mutations: {
    setSnackbar(state, snackbar) {
      state.snackbar.state =  snackbar;
      state.snackbar.content = "Mohon Maaf Menu Belum Tersedia"
    },
    setUser(state, accessUser) {
      state.user = accessUser
      state.authenticated = true
    }
  },
  actions: {
    not_found: function( { commit } ) {
      commit('setSnackbar', true);
    }
  },
  modules: {

  }
})
