const Index = () => import(/* webpackPrefetch: true */ '@/views/private/inventory/Index.vue');

// STOCK
const DataStock = () => import(/* webpackPrefetch: true */ '@/views/private/inventory/stock/Index.vue');

// STOCK CARD
const DataStockCard = () => import(/* webpackPrefetch: true */ '@/views/private/inventory/stock_card/Index.vue');

// STOCK ADJUSTMENT
const DataStockAdjustment = () => import(/* webpackPrefetch: true */ '@/views/private/inventory/stock_adjustment/Index.vue');

// STOCK CONVERSION
const DataStockConversion = () => import(/* webpackPrefetch: true */ '@/views/private/inventory/stock_conversion/Index.vue');

// STOCK TRANSFER
const DataStockTransfer = () => import(/* webpackPrefetch: true */ '@/views/private/inventory/stock_transfer/Index.vue');

const routes = [
  {
    path: "/inventory",
    name: "persediaan",
    component: Index,
    meta: {
      title: "Persediaan"
    }
  },
  {
    path: "/inventory/stock",
    name: "stok",
    component: DataStock,
    meta: {
      title: "Data Stok"
    }
  },
  {
    path: "/inventory/stock-card",
    name: "stok_card",
    component: DataStockCard,
    meta: {
      title: "Data Kartu Stok"
    }
  },
  {
    path: "/inventory/stock-adjustment",
    name: "stok_adjustment",
    component: DataStockAdjustment,
    meta: {
      title: "Data Penyesuaian Stok"
    }
  },
  {
    path: "/inventory/stock-conversion",
    name: "stok_conversion",
    component: DataStockConversion,
    meta: {
      title: "Data Konversi Stok"
    }
  },
  {
    path: "/inventory/stock-transfer",
    name: "stok_transfer",
    component: DataStockTransfer,
    meta: {
      title: "Data Transfer Stok"
    }
  },
]

export default routes.map(route => {
  const meta = {
    all: false, 
    public: false,
    title: route.meta.title
  }
  return { ...route, meta }
})