import Store from "../store"
import { TokenService } from "@/services/Storage.Service.js";
import firebaseapp from "firebase";
import firebase from "@/plugins/Firebase";

const baseUrl = () => {
  if (window.location.hostname !== "localhost") {
    return `${window.location.origin}/api/`;
  } else {
    return "http://localhost:8000/";
  }
};

const getHeader = async () => {
  let store = 0
  let Session = JSON.parse(localStorage.getItem('user'))
  // console.log(Session);
  if(Session !== null){
    if(Object.keys(Session.profile).length > 1 && Object.keys(Session.profile.store_selected_object).length > 1) {
      store = Session.profile.store_selected_object.id
    }
  }
  // console.log('STORE_HEADER', Session)
  return {
    Accept: "application/json",
    "Content-Type": "application/json",
    store
  };
};

const generateParams = (params) => {
  let queryString = "";
  for (let key in params) {
    if (!params.hasOwnProperty(key)) continue;
    if (typeof params[key] == "object") {
      params[key].forEach((item, index) => {
        for (let keyItem in item) {
          queryString += `${key}[${index}][${keyItem}]=${encodeURI(
            item[keyItem]
          )}&`;
        }
      });
    } else {
      queryString += `${key}=${encodeURIComponent(params[key])}&`;
    }
  }
  return queryString == "" ? "" : `?${queryString.replace(/&+$/, "")}`;
};

const logOut = async () => {
  await localStorage.clear();
  setTimeout(() => {
    window.location.href = `${process.env.BASE_URL}`
  }, 2000)
};

const ApiHelpers = {
  get: async (url, params) => {
    // console.log(url, params);
    let Header = await getHeader();
    return new Promise((resolve) => {
      // let uri = baseUrl() + url;
      let uri = baseUrl() + url + generateParams(params);
      fetch(uri, {
        method: "GET",
        credentials: "include",
        headers: {
          ...Header,
        },
      })
        .then((response) => {
          // Status reponse header
          return response.json();
        })
        .then((responseData) => {
          // Status reponse data
          if (responseData.status === 401) {
            logOut();
            Store.state.snackbar.state = true;
            Store.state.snackbar.content = responseData.message;
          } else {
            if (responseData.status === 404) {
              return resolve({
                status: 404,
                message: "Page Not Found.",
              });
            } else {
              return resolve(responseData);
            }
          }
        })
        .catch((error) => {
          return resolve({
            status: 500,
            message: "Terjadi Kesalahan Server.",
            error,
          });
        });
    });
  },
  post: async (url, body, validationMessage) => {
    let Header = await getHeader();
    return new Promise((resolve) => {
      fetch(baseUrl() + url, {
        method: "POST",
        credentials: "include",
        headers: {
          ...Header,
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          // Status reponse header
          return response.json();
        })
        .then((responseData) => {
          // Status reponse data
          if (responseData.status === 401) {
            logOut();
            Store.state.snackbar.state = true;
            Store.state.snackbar.content = responseData.message;
          } else {
            if (responseData.status === 412) {
              if (validationMessage) {
                const errorObject = responseData.results.data;
                for (const key in errorObject) {
                  if (Object.hasOwnProperty.call(errorObject, key)) {
                    const element = errorObject[key];
                    validationMessage[key] = element;
                  }
                }
              }
            }
            if (responseData.status === 404) {
              return resolve({
                status: 404,
                message: "Page Not Found.",
              });
            } else {
              return resolve(responseData);
            }
          }
        })
        .catch((error) => {
          return resolve({
            status: 500,
            message: "Terjadi Kesalahan Server.",
            error,
          });
        });
    });
  },
};

export default ApiHelpers;